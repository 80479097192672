import React, {useEffect, useState} from "react";
import {Formik, Field, Form, useFormikContext, FieldArray} from "formik";
import * as Yup from "yup";
import MilanLogoWide from "../../images/Milan_Logo_White_Wide.svg";
import "./upsellCommissionCalculator.css";

const goalMetPercent = 0.05;
const goalNotMetPercent = 0.03;
const initialValues = {
  cs: {
    count: "0",
    hours: [],
  },
  provider: {
    count: "1",
    hours: [""],
  },
  totalUpsell: "",
};

const validationSchema = Yup.object().shape({
  cs: Yup.object({
    count: Yup.string().required("Required"),
    //for the element is required
    hours: Yup.array(Yup.number().required("Required")),
  }),
  provider: Yup.object({
    count: Yup.string().required("Required"),
    //the array needs to be at least one
    hours: Yup.array(Yup.number().required("Required")).min(1, "At least one provider is required"),
  }),
  totalUpsell: Yup.string().required("Required"),
});

//generates dynamic fields for providers hours and cs hours
const DynamicFieldArray = ({errors, touched}) => {
  const {values, setFieldValue} = useFormikContext();

  useEffect(() => {
    let currentLength = values.provider.hours.length;
    let desiredLength = +values.provider.count;

    if (currentLength < desiredLength) {
      for (let i = currentLength; i < desiredLength; i++) {
        setFieldValue(`provider.hours[${i}]`, "");
      }
    } else if (currentLength > desiredLength) {
      setFieldValue(`provider.hours`, values.provider.hours.slice(0, desiredLength));
    }

    currentLength = values.cs.hours.length;
    desiredLength = +values.cs.count;
    if (currentLength < desiredLength) {
      for (let i = currentLength; i < desiredLength; i++) {
        setFieldValue(`cs.hours[${i}]`, "");
      }
    } else if (currentLength > desiredLength) {
      setFieldValue(`cs.hours`, values.cs.hours.slice(0, desiredLength));
    }
    // });
  }, [values.provider.count, values.cs.count, setFieldValue, values.cs.hours, values.provider.hours]);

  return (
    <>
      <div className="row bg-white row-bg-color-even">
        <div className="col-sm-6">
          <FieldArray name="provider.hours">
            {() => (
              <>
                {values.provider.hours.map((_, x) => (
                  <div key={x} className="row hours-input py-1">
                    <div className="col-7 d-flex align-items-center justify-content-center">
                      <label className="text-capitalize" htmlFor={`provider.hours[${x}]`}>
                        Provider #{x + 1} Hours*
                      </label>
                    </div>
                    <div className="col-5 d-flex justify-content-start">
                      <Field
                        className={`form-control ${errors?.provider?.hours[x] && touched?.provider?.hours && touched.provider.hours[x] ? "field-error" : ""}`}
                        type="number"
                        name={`provider.hours[${x}]`}
                        min="1"
                        step="any"
                      />
                    </div>
                  </div>
                ))}
              </>
            )}
          </FieldArray>
        </div>
        <div className="col-sm-6">
          <FieldArray name="cs.hours">
            {() => (
              <>
                {values.cs.hours.map((_, x) => (
                  <div key={x} className="row hours-input py-1">
                    <div className="col-7 d-flex align-items-center justify-content-center">
                      <label className="text-capitalize" htmlFor={`cs.hours[${x}]`}>
                        Client Specialist #{x + 1} Hours*
                      </label>
                    </div>
                    <div className="col-5 d-flex justify-content-start">
                      <Field
                        className={`form-control ${errors?.cs?.hours[x] && touched?.cs?.hours && touched.cs.hours[x] ? "field-error" : ""}`}
                        type="number"
                        name={`cs.hours[${x}]`}
                        min="1"
                        step="any"
                      />
                    </div>
                  </div>
                ))}
              </>
            )}
          </FieldArray>
        </div>
      </div>
    </>
  );
};

//generates commissions for each providers and cs
const generateCommission = (values) => {
  let commissions = [];
  let totalHours = 0;
  const reformattedUpsellTotal = values.totalUpsell.replace(/,/g, "");
  const inputNumMax = Math.max(values.provider.count, values.cs.count);

  totalHours += values.provider.hours.reduce((x, value) => x + value, 0);
  totalHours += values.cs.hours.reduce((x, value) => x + value, 0);

  for (let i = 1; i <= inputNumMax; i++) {
    const providerComMet = Math.floor((reformattedUpsellTotal * goalMetPercent * values.provider.hours[i - 1]) / totalHours);
    const providerComNotMet = Math.floor((reformattedUpsellTotal * goalNotMetPercent * values.provider.hours[i - 1]) / totalHours);
    const csComMet = Math.floor((reformattedUpsellTotal * goalMetPercent * values.cs.hours[i - 1]) / totalHours);
    const csComNotMet = Math.floor((reformattedUpsellTotal * goalNotMetPercent * values.cs.hours[i - 1]) / totalHours);
    commissions.push(
      <div key={i} className="row bg-white py-2 border-bottom-radius">
        <div className="col col-sm-6 d-flex align-items-center justify-content-center">
          {values.provider.count !== 0 && values.provider.count <= inputNumMax && i <= values.provider.count && (
            <p className="my-2">
              {"Provider #" + i + " = "}
              <span className="fw-bold" style={{color: "#00A5B4"}}>
                ${Intl.NumberFormat().format(providerComMet)}
              </span>{" "}
              |{" "}
              <span className="fw-bold" style={{color: "#F13A1A"}}>
                ${Intl.NumberFormat().format(providerComNotMet)}
              </span>
            </p>
          )}
        </div>
        <div className="col col-sm-6 d-flex align-items-center justify-content-center">
          {values.cs.count !== 0 && values.cs.count <= inputNumMax && i <= values.cs.count && (
            <p className="my-2">
              {"Client Specialist #" + i + " = "}{" "}
              <span className="fw-bold" style={{color: "#00A5B4"}}>
                {/* ${csComMet} */}${Intl.NumberFormat().format(csComMet)}
              </span>{" "}
              |{" "}
              <span className="fw-bold" style={{color: "#F13A1A"}}>
                {/* ${csComNotMet} */}${Intl.NumberFormat().format(csComNotMet)}
              </span>
            </p>
          )}
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="row bg-white border-bottom-radius">
        <div>{commissions}</div>
      </div>
    </>
  );
};

//generates the dropdown for cs and providers
const MySelect = ({empType, arr}) => (
  <Field as="select" className="form-select" name={`${empType}.count`} aria-label={`${empType}.count`}>
    {arr.map((x) => (
      <option key={x} value={x}>
        {x}
      </option>
    ))}
  </Field>
);

//RENDER
function UpsellCommisionCalcHome() {
  const [showFirstPage, setShowFirstPage] = useState(true);
  const showCommission = () => {
    setShowFirstPage(!showFirstPage);
  };

  const handleSubmit = () => {
    setShowFirstPage(!showFirstPage);
  };
  const handleReset = (resetForm) => {
    resetForm();
    setShowFirstPage(!showFirstPage);
  };

  return (
    <div className="" id="upsell-calc">
      <div className="home-btn m-2 text-center d-flex justify-content-center pt-1"/>
      <div className="bg-turquoise text-center d-flex justify-content-center" style={{height: "4rem"}}>
        <img src={MilanLogoWide} alt="Milan Logo Wide" style={{maxWidth: "340px"}} />
      </div>
      <Formik id="my-form" initialValues={initialValues} validationSchema={validationSchema} enableReinitialize onSubmit={handleSubmit}>
        {({values, errors, touched, resetForm, setFieldValue}) => {
          return (
            <>
              <Form>
                <div className="container mt-5 bg-light-turquoise border-turquoise limit-width">
                  {/* <div className=""> */}
                  <h1 className="text-center my-4 font-dark-turquoise text-uppercase h1-small mb-2">
                    <strong>Commission Calculator</strong>
                  </h1>
                  {/* first page with the user input*/}
                  {showFirstPage && (
                    <div className="d-flex justify-content-center">
                      <div style={{maxWidth: "300px"}}>
                        <div className="mt-2">
                          <label htmlFor="provider.count">Number of Providers</label>
                          <MySelect empType="provider" arr={[1, 2, 3, 4, 5]} />
                        </div>
                        <div className="mt-3">
                          <label htmlFor="cs.count">Number of Client Specialists</label>
                          <MySelect empType="cs" arr={[0, 1, 2, 3, 4, 5]} />
                        </div>
                        <div className="mt-3">
                          <label htmlFor="totalUpsell">Upsell Total</label>
                          <div className="dollar-sign">
                            <Field
                              className={`form-control  ${errors.totalUpsell && touched.totalUpsell ? "field-error" : ""}`}
                              name="totalUpsell"
                              onChange={(e) => {
                                // Reformat the input value as the user types
                                const newValue = e.target.value;
                                //limits the user to type in only certain values and amount of values
                                //first it limites the user to only typing in numbers and period
                                //second it limits the user to type in up to 6 whole number digits and 2 digits for the decimal
                                //third, it adds in a comma every three digits
                                const reformattedValue = newValue
                                  .replace(/^0+|[^0-9]/g, "")
                                  .replace(/^(\d{1,6}(?:\.\d{0,2})?).*$/, "$1")
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                // Update Formik’s state
                                setFieldValue("totalUpsell", reformattedValue);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {showFirstPage && (
                    <div className="mt-4">
                      <DynamicFieldArray errors={errors} touched={touched} />
                    </div>
                  )}

                  {/* second page. commission result page */}
                  {!showFirstPage && (
                    <>
                      <div className="text-center">
                        <p>Upsell Total</p>
                        <h1 style={{color: "#00a5b4"}}>{"$" + values.totalUpsell}</h1>
                        <p>
                          Review Goal Met ={" "}
                          <span className="fw-bold" style={{color: "#00A5B4"}}>
                            ${Intl.NumberFormat().format(Math.floor(values.totalUpsell.replace(/,/g, "") * goalMetPercent))}
                          </span>
                        </p>
                        <p>
                          Review Goal Not Met ={" "}
                          <span className="fw-bold" style={{color: "#F13A1A"}}>
                            ${Intl.NumberFormat().format(Math.floor(values.totalUpsell.replace(/,/g, "") * goalNotMetPercent))}
                          </span>
                        </p>
                      </div>
                    </>
                  )}
                  {!showFirstPage && <div className="mt-4">{generateCommission(values)}</div>}
                </div>

                <div className="mt-4 d-flex justify-content-center">
                  <div className="row">
                    {showFirstPage && (
                      <button type="submit" className="btn milan-btn btn-primary milan-btn" style={{width: "100px"}}>
                        Next
                      </button>
                    )}
                    {!showFirstPage && (
                      <div className="row text-center">
                        <div className="col-sm-6 mb-2 mb-sm-0">
                          <button type="button" className="btn btn-primary milan-btn" onClick={showCommission} style={{width: "100px"}}>
                            Back
                          </button>
                        </div>
                        <div className="col-sm-6">
                          <button
                            type="button"
                            className="btn btn-primary milan-btn"
                            onClick={() => {
                              handleReset(resetForm);
                            }}
                            style={{width: "100px"}}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </div>
  );
}

export default UpsellCommisionCalcHome;
