import "./App.css";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/home/Home";
import SalesPresentationHome from "./components/salesPresentation/SalesPresentationHome";
import StoreRankCalculatorHome from "./components/storeRankCalculator/StoreRankCalculatorHome";
import UpsellCommisionCalcHome from "./components/upsellCommissionCalculator/UpsellCommisionCalcHome";
// import UpsellCommisionCalcHomeNew from "./components/upsellCommissionCalculator/UpsellCommisionCalcHomeNew";
// import Ahmad from "./components/upsellCommissionCalculator/Ahmad";
import ErrorPage from "./components/ErrorPage";

function App() {
  return (
    <Router>
      {/* if you want navbar, put it here */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/upsell" element={<UpsellCommisionCalcHome />} />
        {/* <Route path="/upselljuho" element={<UpsellCommisionCalcHome />} /> */}
        {/* <Route path="/upselljuhonew" element={<UpsellCommisionCalcHomeNew />} /> */}
        <Route path="/salespresentation" element={<SalesPresentationHome />} />
        <Route path="/storerankcalculator" element={<StoreRankCalculatorHome />} />
        <Route path="*" element={<ErrorPage/>}/>
      </Routes>
    </Router>
  )
}

export default App;
