import React from "react";
import "./home.css";
import { useNavigate } from "react-router-dom";
import milanLogo from "../../images/logo.webp";

function Home() {
  let navigate = useNavigate();

  return (
    <div id="home" className="container text-center mt-5">
      {/* <h1 className="mb-5">Milan Laser Clinic Apps</h1> */}
      <img className="mb-4" src={milanLogo} alt="Milan Logo" style={{ width: "200px" }} />
      <br />
      <div
        className="btn btn-red my-2 text-white text-uppercase"
        role="button"
        onClick={() => {
          navigate("/salespresentation");
        }}
      >
        Sales Presentation
      </div>
      <br />
      <a className="btn btn-red my-2 text-white text-uppercase" href="https://qc.milanlaser.com/" role="button">
        Quote Calculator
      </a>
      <br />
      <a className="btn btn-blue my-2 text-white text-uppercase" href="https://survey.milanlaser.com/" role="button">
        Client Satisfaction Survey
      </a>
      <br />
      {/* <a className="btn btn-blue my-2 text-white text-uppercase" href="https://upsell.milanlaser.com/" role="button">
        Commission calculator
      </a> */}
      <div
        className="btn btn-blue my-2 text-white text-uppercase"
        role="button"
        onClick={() => {
          navigate("/upsell");
        }}
      >
        Commission Calculator
      </div>
      <br />
      {/* <a className="btn btn-blue my-2 text-white text-uppercase" href="https://storerank.milanlaser.com/" role="button">
        Store Rank Calculator
      </a> */}
      <div
        className="btn btn-blue my-2 text-white text-uppercase"
        role="button"
        onClick={() => {
          navigate("/storerankcalculator");
        }}
      >
        Store Rank Calculator
      </div>
      <br />
    </div>
  );
}

export default Home;
